import { Link } from "react-router-dom";
import { SupportIcon } from '@heroicons/react/outline'
import { useContentful } from 'react-contentful';

export default function Columns({ fields }) {
  const { data, error, fetched, loading } = useContentful({
    contentType: 'home_columns',
    query: {
      limit: 3,
      'order': 'sys.createdAt'
    }
  });

  if (loading) return <p>loading</p>;
  if (error) return <p>error</p>;

  return !loading && !error && data !== null && typeof data.then === 'undefined' && fetched ? (
      data.items.map((postData, index) => {
      let post = postData.fields;

      return (
      <div key={index} className="flex flex-col bg-white rounded-2xl shadow-xl">
        <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
          <div className="absolute top-0 p-5 inline-block bg-green-600 rounded-xl shadow-lg transform -translate-y-1/2">
            <SupportIcon className="h-6 w-6 text-white"  />
          </div>
          <h3 className="text-xl font-medium text-gray-900">{post.title}</h3>
          <p className="mt-4 text-base text-gray-500">{post.text}</p>
        </div>
        <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
          <Link to={post.link} className="text-base font-medium text-green-700 hover:text-green-600">
            {post.link_text} <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>
      </div>
     )}
  )): ''
}
