import { Link } from "react-router-dom";

export default function ContactBanner() {
  return (
    <div class="bg-green-50">
      <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 class="text-3xl font-extrabold tracking-tight text-green-900 sm:text-4xl">
          <span class="block">Geinteresseerd?</span>
          <span class="block text-green-600">Neem vrijblijvend contact met ons op!</span>
        </h2>
        <div class="mt-8 flex lg:flex-shrink-0 lg:mt-0">
          <div class="inline-flex rounded-md shadow">
            <Link to="/contact" class="bg-green-600 border border-transparent rounded-md py-3 px-5 inline-flex items-center justify-center text-base font-medium text-white hover:bg-green-700">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
