import { Link } from "react-router-dom";
import { useContentful } from 'react-contentful';

export default function Article({ fields }) {
  const { data, error, loading, fetched } = useContentful({
    contentType: 'home_articles_werkwijze',
    query: {
      limit: 3,
    }
  });

  if (loading) return <p>loading</p>;
  if (error) return <p>error</p>;

  return !loading && !error && data !== null && typeof data.then === 'undefined' && data.items !== undefined && fetched ? (
     data.items.map((postData, index) => {
      let post = postData.fields.articleBlog.fields;

      return (
      <div key={index} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src={`${post.image.fields.file.url}?w=1920&h=1080`} alt="" />
        </div>
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <p className="text-sm font-medium text-green-600">
              <Link to="/" className="hover:underline">
                {post.sub_title}
              </Link>
            </p>
            <Link to="/" className="block mt-2">
              <p className="text-xl font-semibold text-gray-900">{post.title}</p>
              <p className="mt-3 text-base text-gray-500 text-ellipsis">{(post.text.content[0].content[0].value).substring(0, 200)}...</p>
            </Link>
          </div>
        </div>
        <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl pt-0">
          <Link to="/werkwijze" className="text-base font-medium text-green-700 hover:text-green-600">
            Lees meer <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>
      </div>
     )}
  )): ''
}
