// Components
import ArticleReference from '../../components/home/article-reference'
import IntershipBanner from '../../components/home/internship-banner'
import BannerColumns from '../../components/home/banner-columns'

import { useContentful } from 'react-contentful';

function App() {
  let bannerInfo = {};

  const { data, error, loading, fetched } = useContentful({
    contentType: 'home_banner',
    query: {
      'limit': 1,
    }
  });

  if (loading) return <p>loading</p>;
  if (error) return <p>error</p>;

  if (!error && !loading && data != null && typeof data.then === 'undefined' && fetched) {
    bannerInfo = data.items[0].fields;

    console.log(bannerInfo)
  }

  return (
    <>
      <div className="bg-white">
        {/* Header */}
        {!loading && !error && data !== null && typeof data.then === 'undefined' && fetched ?
          <div className="relative pb-32 bg-gray-300">
            <div className="absolute inset-0">
              <img
                className="w-full h-full object-cover"
                  src={`${bannerInfo.bannerImg.fields.file.url}?w=1920&h=1080`}
                alt=""
              />
              <div className="absolute inset-0 bg-gray-600 mix-blend-multiply" aria-hidden="true" />
            </div>
            <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
              <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
                {bannerInfo.title}
              </h1>
              <p className="mt-6 max-w-3xl text-xl text-gray-300">
              {bannerInfo.description}
              </p>
            </div>
          </div>
          :
          <></>
        }

        {/* Overlapping cards */}
        <section
          className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
          aria-labelledby="contact-heading"
        >
          <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
            <BannerColumns />
          </div>
        </section>
      </div>

      {/* Stage lopen  */}
      <IntershipBanner />

      {/* Werkwijze */}
      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              Onze werkwijze
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              Bij Ieniemini groeien kinderen op in een veilige en vertrouwde omgeving, ook werken wij volgens de nieuwste opvoedtechnieken.
            </p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            <ArticleReference />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
