import React, { useEffect, useState } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import { useContentful } from 'react-contentful';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useHref } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';

import InfoSection from './info-section';

const grecaptchaObject = window.grecaptcha

const containerStyle = {
  width: '500px',
  height: '500px'
};

const center = {
  lat: 52.2952909,
  lng: 5.619594
};

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <b>{text}</b>,
    [MARKS.ITALIC]: text => <i>{text}</i>,
  },
  renderNode: {
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return <li>{children}</li>;
    },
    [BLOCKS.UL_LIST]: (node, children) => <ul className="list-disc ml-4">{children}</ul>,
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="mb-4">{children}</p>,
  },
  renderText: text => {
    let splitUpText = text.split(/\r?\n/);

    return splitUpText.map((actText, index) => (
      <>
        {actText.replace(String.fromCharCode(160), ' ')}
        {actText === "" && splitUpText[index - 1] !== "" ? index === 0 ? <br /> : <><br /> <br /></> : ''}
      </>
    ));
  }
};

function App() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyD2HZwmEIxeNVVm3MYRPEhayBsu4xJGMW4"
  })

  const [data, setData] = useState({
    name: '',
    email: '',
    description: '',
    message: '',
  })

  const [success, setSuccess] = useState(false);

  const onMapClick = () => {
    window.open('https://www.google.com/search?q=kinderopvang+ieniemini&rlz=1C5CHFA_enNL987NL987&tbm=lcl&sxsrf=APq-WBuDf_sfy59M8FqNCiu5nq0tiGVg7w%3A1648494749521&ei=nQhCYrm4H87SsAeRnImoDQ&oq=kinderopvang+ieniemini#rlfi=hd:;si:4678657861222770029,l,ChZraW5kZXJvcHZhbmcgaWVuaWVtaW5pWiAiFmtpbmRlcm9wdmFuZyBpZW5pZW1pbmkqBggCEAAQAZIBD2RheV9jYXJlX2NlbnRlcg;mv:[[52.29536547731902,5.619894876038038],[52.29500552268095,5.619306323961962]]', '_blank').focus();
  }

  const sendMessage = async () => {
    const token = await executeRecaptcha('yourAction');

    let postData = { ...data, captcha: token }

    axios.post('https://api.kinderopvang-ieniemini.nl/api/contact', postData)
      .then(response => {
        setData({
          name: '',
          email: '',
          description: '',
          message: '',
        });

        setSuccess(true)
      })
      .catch(error => {
        console.error('Failed to send message:', error);
        // Handle error
      });
  };

  return isLoaded ? (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>

      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={18}
            onClick={onMapClick}
          >
            <Marker
              position={{
                lat: 52.29517000,
                lng: 5.619581
              }}
            />
          </GoogleMap>
        </div>

        <div className="bg-white py-16  px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <div className="grid grid-cols-1 gap-y-6">
              {/* <div>
                <label htmlFor="full-name" className="sr-only">
                  Volledige naam
                </label>
                <input
                  type="text"
                  name="full-name"
                  onChange={(e) => { setData({ ...data, name: e.target.value }) }}
                  id="full-name"
                  value={data.name}
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Volledige naam"
                />
              </div>

              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  value={data.email}
                  onChange={(e) => { setData({ ...data, email: e.target.value }) }}
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Email"
                />
              </div>

              <div>
                <label htmlFor="description" className="sr-only">
                  Onderwerp
                </label>
                <input
                  type="text"
                  name="description"
                  onChange={(e) => { setData({ ...data, description: e.target.value }) }}
                  value={data.description}
                  id="description"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Onderwerp"
                />
              </div>

              <div>
                <label htmlFor="message" className="sr-only">
                  Bericht
                </label>
                <textarea
                  id="message"
                  name="message"
                  onChange={(e) => { setData({ ...data, message: e.target.value }) }}
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                  placeholder="Bericht"
                  value={data.message}
                />
              </div>
              {success ?
                <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                  <div className="flex">
                    <div className="py-1">
                      <p className="font-bold">Bericht succesvol verstuurd</p>
                      <p className="text-sm">We zullen binnen 3 tot 5 werkdagen reageren</p>
                    </div>
                  </div>
                </div>
                :
                <button
                  type="submit"
                  onClick={sendMessage}
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Verstuur
                </button>
              } */}
              <p>info@kinderopvang-ieniemini.nl</p>
            </div>
          </div>
        </div>
      </div>
      <InfoSection />
    </div>
  ) : <></>;
}

export default App;
