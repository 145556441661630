import { Link } from "react-router-dom";

function App() {
  return (
    <>
      <main className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 my-auto py-16 sm:py-32 h-96">
          <p className="text-sm font-semibold text-green-600 uppercase tracking-wide">404 error</p>
          <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Deze URL bestaat niet</h1>
          <p className="mt-2 text-base text-gray-500">Sorry, we konden niet vinden waar je naar zocht!</p>
          <div className="mt-6">
            <Link to="/" className="text-base font-medium text-green-600 hover:text-green-500">
              Ga terug naar de homepagina<span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}

export default App;
