import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React, { useEffect, useState } from 'react';
import { useContentful } from 'react-contentful';
import ReactPaginate from 'react-paginate';

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <b>{text}</b>,
    [MARKS.ITALIC]: text => <i>{text}</i>,
  },
  renderNode: {
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return <li>{children}</li>;
    },
    [BLOCKS.UL_LIST]: (node, children) => <ul className="list-disc ml-4">{children}</ul>,
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="mb-4">{children}</p>,
  },
  renderText: text => {
    let splitUpText = text.split(/\r?\n/);

    return splitUpText.map((actText, index) => (
      <>
        {actText.replace(String.fromCharCode(160), ' ')}
        {actText === "" && splitUpText[index - 1] !== "" ? index === 0 ? <br /> : <><br /> <br /></> : ''}
      </>
    ));
  }
};

export default function Doelstelling() {
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [updateItems, hasUpdatedItems] = useState(false);
  const [items, setItems] = useState([])
  const itemsPerPage = 3;
  const [currentItems, setCurrentItems] = useState(null);

  const { data, error, loading } = useContentful({
    contentType: 'doelstelling_articles',
    query: {
      'order': 'sys.createdAt'
    }
  });

  useEffect(() => {
    if (!loading && !error && data !== null && items.length !== 0) {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    }
  }, [loading, error, data, itemOffset, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;

    setItemOffset(newOffset);

    setTimeout(() => {
      document.querySelector('body').scrollIntoView({ behavior: 'smooth' })
    }, 50);
  }

  if (!loading && !error && data !== null && updateItems === false) {
    hasUpdatedItems(true);

    setItems(data.items);

    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(data.items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.items.length / itemsPerPage));
  }

  return !loading && !error && data !== null && currentItems !== null ? (
    <div className="relative py-16 bg-white overflow-hidden mt-4">
      <div className="relative px-4 sm:px-6 lg:px-8 max-w-screen-md mx-auto">
        {currentItems.map((item, index) => (
          <div key={index} className="mb-12 prose prose-green">
             <div className="text-lg max-w-prose">
                <h1>
                  <span className="mt-2 block text-xl text-center leading-8 tracking-tight text-green-600 sm:text-3xl">
                    {item.fields.title}
                  </span>
                </h1>
                </div>
                <div className="mt-6 prose prose-green prose-lg text-gray-600">
                  {documentToReactComponents(item.fields.text, options)}
                </div>
            </div>
          ))}

        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">Je bekijkt
                <span className="font-medium"> {(itemOffset / 3) + 1} </span>
                van de
                <span className="font-medium"> {pageCount}</span> pagina's met
                <span className="font-medium"> {data.items.length}</span> resultaten
              </p>
            </div>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="<"
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                pageLinkClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                activeLinkClassName="z-10 bg-green-50 border-green-500 text-green-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                previousLinkClassName="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                nextLinkClassName="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                renderOnZeroPageCount={null}
              />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="mt-4">
    <p>Loading..</p>
  </div>
  )
}
