import ReactDOM from 'react-dom'
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.css';

// Contentful
import { ContentfulClient, ContentfulProvider } from 'react-contentful';

// Captcha
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

// Layout components
import Navbar from './components/layout/header'
import Footer from './components/layout/footer'

// Pages
import Home from './pages/home'
import Doelstelling from './pages/doelstelling'
import Werkwijze from './pages/werkwijze';
import Internship from './pages/intership';
import InternshipSignup from './pages/intership/form';
import Contact from './pages/contact'
import Privacy from './pages/privacy'


import NotFound from './pages/404'

const contentfulClient = new ContentfulClient({
  accessToken: 'oB3V2nW3jhoy9nxo-Kh9-M0Kop_2uQcWQH_fI7DE6FU',
  space: '9u6l4cgua0k6',
});

ReactDOM.render(
  <ContentfulProvider client={contentfulClient}>
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path='*' element={<NotFound />} />

        <Route path="/" element={<Home />} />
        <Route path="/werkwijze" element={<Werkwijze />} />
        <Route path="/doelstelling" element={<Doelstelling />} />
        <Route path="/stage" element={<Internship />} />
        <Route path="/stage/aanmelden" element={<InternshipSignup />} />
        <Route path="/contact" element={<GoogleReCaptchaProvider reCaptchaKey="6LfLd7AlAAAAADnByGww23tlN3oe8InWtIFbpVj7"><Contact /></GoogleReCaptchaProvider>} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </ContentfulProvider>,
  document.getElementById('root')
);
