import { useContentful } from 'react-contentful';

// Component
import Article from '../../components/werkwijze/article'
import ContactBanner from '../../components/contact-us';

export default function Werkwijze() {
  const { data, error, loading } = useContentful({
    contentType: 'werkwijze_article',
  });

  if (loading) return <p>loading</p>;
  if (error) return <p>error</p>;

  return (
    <div className="mt-4">
      {loading === false && !error && data != null ? data.items.map((article, index) => <Article fields={article.fields} left={index % 2} />) : ''}

      <ContactBanner />
    </div>
  )
}
