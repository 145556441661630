import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useContentful } from 'react-contentful';

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <b>{text}</b>,
    [MARKS.ITALIC]: text => <i>{text}</i>,
  },
  renderNode: {
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return <li>{children}</li>;
    },
    [BLOCKS.UL_LIST]: (node, children) => <ul className="list-disc ml-4">{children}</ul>,
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="mb-4">{children}</p>,
  },
  renderText: text => {
    let splitUpText = text.split(/\r?\n/);

    return splitUpText.map((actText, index) => (
      <>
        {actText.replace(String.fromCharCode(160), ' ')}
        {actText === "" && splitUpText[index - 1] !== "" ? index === 0 ? <br /> : <><br /> <br /></> : ''}
      </>
    ));
  }
};

export default function Privacy() {
  const { data, error, loading } = useContentful({
    contentType: 'privacy_verklaring',
    query: {
      'order': 'sys.createdAt'
    }
  });

  if (!loading && !error && data !== null) {
    console.log(data);
  }


  return !loading && !error && data !== null ? (
    <div className="relative py-16 bg-white overflow-hidden mt-4">
      <div className="relative px-4 sm:px-6 lg:px-8 max-w-screen-md mx-auto">
        {data.items.map((item, index) => (
          <div key={index} className="mb-12 prose prose-green">
            <div className="text-lg max-w-prose">
              <h1>
                <span className="mt-2 block text-xl text-center leading-8 tracking-tight text-green-600 sm:text-3xl">
                  {item.fields.title}
                </span>
              </h1>
            </div>
            <div className="mt-6 prose prose-green prose-lg text-gray-600">
              {documentToReactComponents(item.fields.text, options)}
            </div>
          </div>
        ))}

      </div>
    </div>
  ) : (
    <div className="mt-4">
      <p>Loading..</p>
    </div>
  )
}
