import { NavLink, Link } from "react-router-dom";
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

const defaultStyle = "inline-flex items-center px-1 pt-1 border-b-2 font-medium"

const pages = [
  { name: 'Home', href: '/' },
  { name: 'Werkwijze', href: '/werkwijze' },
  { name: 'Doelstelling', href: '/doelstelling' },
  { name: 'Stage', href: '/stage' },
  { name: 'Contact', href: '/contact' },
]

export default function Navbar() {
  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="hidden lg:block max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
            <div className="flex px-2 lg:px-0 justify-between h-32">
              <Link to="/" className="flex-shrink-0 flex items-center">
                <img
                  className="hidden lg:block h-20 w-auto"
                  src="assets/logo.png"
                  alt="Workflow"
                />
              </Link>
              <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                {pages.map((page, index) => (
                  <NavLink
                    key={index}
                    to={page.href}
                    className={({ isActive }) => isActive ? `border-green-500 text-gray-900 ${defaultStyle}` : `border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 ${defaultStyle}`}
                  >
                    {page.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>

          {/* Mobile navbar */}
          <div className="flex lg:hidden px-2 sm:px-4 py-4 justify-between">
            <img
              className="block lg:hidden h-12 w-auto"
              src="assets/logo.png"
              alt="Workflow"
            />
            <div className="flex items-center lg:hidden">
              {/* Mobile menu button */}
              <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
            </div>
          </div>

          {/* Expendable mobile navigation */}
          <Disclosure.Panel className="lg:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {pages.map((page, index) => (
                <NavLink
                  key={index}
                  to={page.href}
                  className={({ isActive }) => isActive ?
                    "bg-green-50 border-green-500 text-green-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"}
                >
                  {page.name}
                </NavLink>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
