import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useContentful } from 'react-contentful';

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <b>{text}</b>,
    [MARKS.ITALIC]: text => <i>{text}</i>,
  },
  renderNode: {
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return <li>{children}</li>;
    },
    [BLOCKS.UL_LIST]: (node, children) => <ul className="list-disc ml-4">{children}</ul>,
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="mb-4">{children}</p>,
  },
  renderText: text => {
    let splitUpText = text.split(/\r?\n/);

    return splitUpText.map((actText, index) => (
      <>
        {actText.replace(String.fromCharCode(160), ' ')}
        {actText === "" && splitUpText[index - 1] !== "" ? index === 0 ? <br /> : <><br /> <br /></> : ''}
      </>
    ));
  }
};

export default function Internship() {
  const [updateItems, hasUpdatedItems] = useState(false);
  const [items, setItems] = useState([])

  const { data, error, loading } = useContentful({
    contentType: 'internship_articles',
    query: {
      'order': 'sys.createdAt'
    }
  });

  if (!loading && !error && data !== null && updateItems === false) {
    hasUpdatedItems(true);
    setItems(data.items)
  }

  return !loading && !error && data !== null ? (
    <div className="relative py-16 bg-white overflow-hidden mt-4">
      <div className="px-4 sm:px-6 lg:px-8 max-w-screen-md mx-auto">
        {items.map((item, index) => (
          <div key={index} className="mb-12 prose prose-green">
             <div className="text-lg max-w-prose">
                <h1>
                  <span className="mt-2 block text-xl text-center leading-8 tracking-tight text-green-600 sm:text-3xl">
                    {item.fields.title}
                  </span>
                </h1>
                </div>
                <div className="mt-6 prose prose-green prose-lg text-gray-600">
                  {documentToReactComponents(item.fields.text, options)}
                </div>
                {index === 0 ?
                <div className="flex items-center justify-center mt-10">
                  <Link
                    to="/stage/aanmelden"
                    className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Inschrijf formulier
                  </Link>
                </div>
                : ''}
            </div>
          ))}
      </div>
    </div>
  ) : (
    <div className="mt-4">
    <p>Loading..</p>
  </div>
  )
}
